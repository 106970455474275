<template>
  <div>
    <div class="d-flex justify-content-between align-items-center p-4 pb-0">
      <h3>
        {{ fieldDefinition && $t(`document.documentRecording.${fieldDefinition.label.replace(/\s+/g, '')}`) }}
        <small v-if="itemsParams.price && itemsParams.price.includingTax" class="text-muted">(tax included)</small>
        <el-form-item :prop="fieldKey" :rules="rule" />
      </h3>
      <div class="d-flex">
        <el-dropdown
          trigger="click"
          :placement="$t('direction') === 'rtl' ? 'bottom-start' : 'bottom-end'"
          @command="(method) => _self[method]()"
        >
          <Button type="icon" class="p-0">
            <KebabIcon width="24px" height="24px" />
          </Button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="exportItems">
              {{ $t('document.documentRecording.downloadCsv') }}
            </el-dropdown-item>
            <el-dropdown-item v-if="clearable" command="resetInputData">
              {{ $t('document.documentRecording.reset') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div v-if="model.structureParams.itemPriceMissing" class="float-right me-2">
          <span class="icon icon-calculator" @click="calculateItemPrices" />
        </div>
      </div>
    </div>
    <div class="card table-responsive p-2">
      <table class="item-table">
        <thead>
          <tr>
            <td class="text-center" style="width: 37px"><p class="fw-bold">#</p></td>
            <td v-if="itemsParams.reference" style="min-width: 150px">
              <p class="fw-bold">{{ getHeader('reference') }}</p>
            </td>
            <td v-if="itemsParams.sku" style="min-width: 150px">
              <p class="fw-bold">{{ getHeader('sku') }}</p>
            </td>
            <td v-if="itemsParams.gtin" style="min-width: 150px">
              <p class="fw-bold">{{ getHeader('gtin') }}</p>
            </td>
            <td v-if="itemsParams.name" style="min-width: 200px">
              <p class="fw-bold">{{ getHeader('name') }}</p>
            </td>
            <td v-if="itemsParams.quantity" style="min-width: 85px">
              <p class="fw-bold">{{ getHeader('quantity') }}</p>
            </td>
            <td v-if="itemsParams.packageQuantity" style="min-width: 85px">
              <p class="fw-bold">{{ getHeader('packageQuantity') }}</p>
            </td>
            <td v-if="itemsParams.quantityInPackage" style="min-width: 85px">
              <p class="fw-bold">{{ getHeader('quantityInPackage') }}</p>
            </td>
            <td v-if="itemsParams.price" style="min-width: 85px">
              <p class="fw-bold">{{ getHeader('price') }}</p>
            </td>
            <td v-if="itemsParams.discountRate" style="min-width: 85px">
              <p class="fw-bold">{{ getHeader('discountRate') }}, %</p>
            </td>
            <td v-if="itemsParams.discountAmount" style="min-width: 85px">
              <p class="fw-bold">{{ getHeader('discountAmount') }}, $</p>
            </td>
            <td v-if="itemsParams.totalDiscount" style="min-width: 85px">
              <p class="fw-bold">{{ getHeader('totalDiscount') }}</p>
            </td>
            <td v-if="itemsParams.netPrice" style="min-width: 85px">
              <p class="fw-bold">{{ getHeader('netPrice') }}</p>
            </td>
            <td v-if="itemsParams.totalTax" style="min-width: 140px">
              <p class="fw-bold">{{ getHeader('totalTax') }}</p>
            </td>
            <td v-if="itemsParams.totalPackages" style="min-width: 140px">
              <p class="fw-bold">{{ getHeader('totalPackages') }}</p>
            </td>
            <td v-if="itemsParams.totalDeposits" style="min-width: 140px">
              <p class="fw-bold">{{ getHeader('totalDeposits') }}</p>
            </td>
            <td v-if="itemsParams.totalAmount" style="min-width: 85px">
              <p class="fw-bold">{{ getHeader('totalAmount') }}</p>
            </td>
            <td style="width: 30px"></td>
          </tr>
        </thead>
        <tbody>
          <document-form-item
            v-for="(item, index) in value"
            :key="arrayKeys[index]"
            :field-key="fieldKey"
            :index="index"
            :item-model="item"
            :items-params="itemsParams"
            @add-item-above="addItemRowAbove(index)"
            @remove="removeItem(index)"
            @create-item="createItem(index)"
          />

          <document-form-item
            :key="nextRandomKey"
            :field-key="fieldKey"
            :index="value ? value.length : 0"
            :items-params="itemsParams"
            @add="convertItem"
          />
        </tbody>
      </table>
    </div>
    <AddProductModal
      :visible.sync="itemFormVisible"
      :supplier-id="model.supplierId"
      :defaults="itemFormDefaults"
      @submit="updateWithCreatedItem"
    />
  </div>
</template>

<script type="text/javascript">
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import AutoformArray from '@/modules/templates/components/autoform/autoform_array';
import DocumentFormItem from './document_form_item.vue';
import AddProductModal from '@/modules/products/components/AddProductModal.vue';
import { KebabIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

export default {
  components: { AddProductModal, DocumentFormItem, KebabIcon, Button },
  extends: AutoformArray,
  props: {
    model: { type: Object, required: true },
    itemsParams: { type: Object, required: true },
    clearable: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      nextRandomKey: Math.random(),
      createItemIndex: null,
    };
  },
  computed: {
    itemFormVisible: {
      get() {
        return this.createItemIndex !== null;
      },
      set(value) {
        if (!value) this.createItemIndex = null;
      },
    },
    itemFormDefaults() {
      if (!this.itemFormVisible) return {};
      const item = this.value[this.createItemIndex];
      return {
        code: item.sku,
        name: item.name,
        gtin: item.gtin,
      };
    },
  },
  methods: {
    async convertItem() {
      const focusedInput = this.$el.querySelector('input:focus');
      const allInputs = Array.from(this.$el.querySelectorAll('input'));
      const focusIndex = allInputs.indexOf(focusedInput);

      this.addItem(this.nextRandomKey);
      this.nextRandomKey = Math.random();

      await this.$nextTick();
      const newInput = Array.from(this.$el.querySelectorAll('input'))[focusIndex];
      newInput.focus();
    },
    createItem(index) {
      this.createItemIndex = index;
    },
    updateWithCreatedItem(item) {
      this.$emit('item-created');
      this.value[this.createItemIndex].productId = item.id;
      this.value[this.createItemIndex].sku = item.sku;
      this.value[this.createItemIndex].name = item.name;
    },
    calculateItemPrices() {
      this.value.forEach((item) => {
        if (item.totalAmount && item.quantity) item.price = Math.round(item.totalAmount / item.quantity);
      });
    },
    exportItems() {
      const blob = new Blob([Papa.unparse(this.value)], { type: 'text/csv' });
      FileSaver.saveAs(blob, 'items.csv');
    },
    resetInputData() {
      this.$emit('reset-items');
    },
    getHeader(key) {
      return this.$t(`document.exports.schema.itemsFields.${key}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.item-table {
  ::v-deep td {
    padding: 5px;
  }
}
</style>
